<template lang="pug">
  v-card(flat)
    v-card-text
      v-row
        v-col(cols="1")
          v-btn(
            color="primary"
            icon
            large
            :loading="item.isLoading"
            :disabled="!!item.error"
            :download="filename"
            :href="url"
            @click="$emit('toggle')"
          )
            v-icon {{isChart ? 'mdi-eye' : 'mdi-download'}}
        v-col(cols="11")
          div.text-subtitle-1.black--text(@click="$emit('toggle')") {{report.name}} – {{status}}
          div(@click="$emit('toggle')") {{date}}
          div(v-if="isChart && expanded" )
            component(:datasets="item.data.stats" :is="chartComponent")
    v-divider
</template>

<script>
import { parseDate } from '@/utils/helpers';
import charts from '@/views/reports/charts';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    report: {
      type: Object,
      default: () => ({}),
    },
    expanded: Boolean,
  },
  computed: {
    url() {
      return this.item.data?.url || '';
    },
    filename() {
      return this.item.data?.filename || '';
    },
    isChart() {
      return this.item.data?.stats;
    },
    status() {
      if (this.item.error) {
        return 'Ошибка';
      } else if (this.item.isLoading) {
        return 'Формируется';
      } else if (this.item.data.stats) {
        return 'График сформирован';
      }
      return 'Готов к скачиванию';
    },
    date() {
      return parseDate(this.item.timestamp).toFormat('HH:mm, DD');
    },
    chartComponent() {
      return charts[this.report.chart];
    },
  },
};
</script>

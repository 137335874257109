import { Line } from 'vue-chartjs';
import colors from './colors.js';

// https://github.com/apertureless/vue-chartjs
export default {
  extends: Line,
  props: ['datasets', 'options'],
  data: () => ({
    htmlLegend: null,
  }),
  mounted() {
    const data = [
      {
        label: 'Всего осмотров',
        showLine: true,
        fill: false,
        borderColor: '#287BD8',
        backgroundColor: '#287BD8',
        data: [],
      },
    ];
    const labels = [];

    this.datasets.forEach(item => {
      const name = item.viewResult + ' (' + item.viewType + ')';
      if (!labels.includes(item.date)) labels.push(item.date);

      const index = data.findIndex(item => item.label === name);
      const dateIndex = labels.indexOf(item.date);
      if (index === -1) {
        data.push({
          label: name,
          showLine: true,
          fill: false,
          borderColor: colors[data.length],
          backgroundColor: colors[data.length],
          data: [Number(item.count)],
        });
      } else {
        data[index].data[dateIndex] =
          (data[index].data[dateIndex] || 0) + Number(item.count);
      }
      data[0].data[dateIndex] =
        (data[0].data[dateIndex] || 0) + Number(item.count);
    });

    this.renderChart(
      {
        labels,
        datasets: data,
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Даты',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Количество осмотров',
              },
            },
          ],
        },
      },
    );
  },
};

import { Line } from 'vue-chartjs';
import colors from './colors.js';

// https://github.com/apertureless/vue-chartjs
export default {
  extends: Line,
  props: ['datasets', 'options'],
  data: () => ({
    htmlLegend: null,
  }),
  mounted() {
    const data = [];
    const labels = [];

    this.datasets.forEach(item => {
      const name = item.orgName;
      if (!labels.includes(item.date)) labels.push(item.date);

      const index = data.findIndex(item => item.label === name);
      if (index === -1) {
        data.push({
          label: name,
          backgroundColor: colors[data.length],
          data: [[stringToTime(item.totalTime)]],
        });
      } else {
        const dateIndex = labels.indexOf(item.date);
        if (data[index].data[dateIndex]) {
          data[index].data[dateIndex].push(stringToTime(item.totalTime));
        } else {
          data[index].data[dateIndex] = [stringToTime(item.totalTime)];
        }
      }
    });
    data.forEach(item => {
      item.data = item.data.map(item =>
        Math.round(item.reduce((a, b) => a + b) / item.length),
      );
    });

    this.renderChart(
      {
        labels,
        datasets: data,
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          callbacks: {
            label: (tooltipItem, data) => {
              const { label } = data.datasets[tooltipItem.datasetIndex];
              const time = timeToString(tooltipItem.value);
              const sec = '(' + tooltipItem.value + ' сек.)';
              return label + ': ' + time + ' ' + sec;
            },
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Даты',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                callback(label, index, labels) {
                  return timeToString(label);
                },
              },
              scaleLabel: {
                display: true,
                labelString: 'Продолжительность в минутах',
              },
            },
          ],
        },
      },
    );
  },
};

function stringToTime(string) {
  const [h, m, s] = string.split(':');
  return +h * 3600 + +m * 60 + +s;
}

function timeToString(time) {
  const sec = parseInt(time, 10); // don't forget the second param
  // let hours = Math.floor(sec / 3600);
  let minutes = Math.floor(sec / 60);
  let seconds = sec - minutes * 60;

  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return minutes + ':' + seconds;
}
